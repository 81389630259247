import Client from "./clients/AxiosClient";
import Export from "./clients/ExportFile";
const resource = "/departments";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },

  getInternal(params) {
    return Client.get(`${resource}/internal`, { params: params });
  },

  create(data, id) {
    return Client.post(`${resource}/${id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getUsersByDepartment(id) {
    return Client.get(`${resource}/${id}/users`);
  },
  export() {
    return Export.get(`${resource}/export`);
  },
};
