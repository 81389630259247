import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import middleware from "@grafikri/vue-middleware";
import { abilitiesPlugin } from "@casl/vue";
import { ability } from "@/services/ability";
import "ant-design-vue/dist/antd.css";

import "./scss/app.scss";

const i18nConfig = {
  legacy: false,
  locale: "vi",
  fallbackLocale: "vi",
  messages: {
    en: require("@/i18n/en.json"),
    vi: require("@/i18n/vi.json"),
  },
};
router.beforeEach(middleware({ store }));

createApp(App)
  .use(abilitiesPlugin, ability, { useGlobalProperties: true })
  .use(store)
  .use(router)
  .use(createI18n(i18nConfig))
  .use(Antd)
  .mount("#app");
