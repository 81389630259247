import axios from "axios";
const API_URL = process.env.API_URL; //|| "http://127.0.0.1:3979"

export default {
  request(options: any) {
    return axios({
      method: options.method,
      url: API_URL + options.url,
      data: options.data,
      headers: options.headers || {},
    });
  },
};
