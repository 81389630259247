import Client from "./clients/AxiosClient";
const provincePath = "/provinces";
const districtPath = "/districts";
const wardPath = "/wards";

export default {
  getProvinces(params) {
    return Client.get(`${provincePath}`, { params: params });
  },
  getProvinceById(id) {
    return Client.get(`${provincePath}/${id}`);
  },

  getDistricts(params) {
    return Client.get(`${districtPath}`, { params: params });
  },
  getDistrictById(id) {
    return Client.get(`${districtPath}/${id}`);
  },

  getWards(params) {
    return Client.get(`${wardPath}`, { params: params });
  },
  getWardById(id) {
    return Client.get(`${wardPath}/${id}`);
  },
};
