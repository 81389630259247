import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '../../../public/img/logoHeader.svg'
import _imports_1 from '../../../public/img/home/logo_minify.svg'


const _hoisted_1 = { class: "logo" }
const _hoisted_2 = {
  src: _imports_0,
  alt: ""
}
const _hoisted_3 = {
  src: _imports_1,
  alt: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_openBlock(), _createBlock(_component_a_layout_sider, {
    trigger: null,
    collapsed: _ctx.collapsed,
    collapsible: "",
    width: 270,
    style: {
      overflow: 'auto',
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
    }
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, { to: { name: 'home' } }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
              [_vShow, !_ctx.collapsed]
            ]),
            _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
              [_vShow, _ctx.collapsed]
            ])
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_a_menu, {
        openKeys: [`${_ctx.openKeys}`],
        selectedKeys: [`${_ctx.selectedKeys}`],
        theme: "dark",
        mode: "inline"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routes, (item, key) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (item.children && _ctx.isAllChildrenPermission(key))
                ? (_openBlock(), _createBlock(_component_a_sub_menu, {
                    key: item.name
                  }, {
                    title: _withCtx(() => [
                      _createElementVNode("span", null, [
                        (item.icon)
                          ? (_openBlock(), _createBlock(_component_Icon, {
                              key: 0,
                              icon: item.icon
                            }, null, 8, ["icon"]))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.displayName)), 1)
                      ])
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childrenHavePermission(item.children), (child) => {
                        return (_openBlock(), _createBlock(_component_a_menu_item, {
                          key: child.name
                        }, {
                          default: _withCtx(() => [
                            (_ctx.$can('read', child.subject))
                              ? (_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  to: child.path
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t(child.displayName)), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true),
              (!item.children && _ctx.$can('read', item.subject))
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    key: item.name
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_router_link, {
                        to: item.path
                      }, {
                        default: _withCtx(() => [
                          (item.icon)
                            ? (_openBlock(), _createBlock(_component_Icon, {
                                key: 0,
                                icon: item.icon
                              }, null, 8, ["icon"]))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t(item.displayName)), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    _: 2
                  }, 1024))
                : _createCommentVNode("", true)
            ], 64))
          }), 256))
        ]),
        _: 1
      }, 8, ["openKeys", "selectedKeys"])
    ]),
    _: 1
  }, 8, ["collapsed"]))
}