import Client from "./clients/AxiosClient";
const resource = "/courses";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  create(course) {
    return Client.post(`${resource}`, course);
  },
  update(course) {
    return Client.put(`${resource}/${course.courseId}`, course);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  getCourseStudent(id, params) {
    return Client.get(`${resource}/${id}/student`, { params: params });
  },
  updateCourseStudent(id, data, action) {
    return Client.post(`${resource}/${id}/student?action=${action}`, data);
  },
  doneLesson(users, courses, chapters, lessons) {
    return Client.put(
      `/users/${users}/courses/${courses}/chapters/${chapters}/lessons/${lessons}`
    );
  },
  userCourses(courses, users) {
    return Client.get(`/users/${users}/courses/${courses}`);
  },
  joinCourses(courses, users) {
    return Client.post(`/users/${users}/courses/${courses}`);
  },
  startQuiz(users, courses, chapters, tests) {
    return Client.put(
      `/users/${users}/courses/${courses}/chapters/${chapters}/tests/${tests}`
    );
  },
  getQuestions(users, courses, chapters, tests, user_test) {
    return Client.get(
      `/users/${users}/courses/${courses}/chapters/${chapters}/tests/${tests}/user-test/${user_test}`
    );
  },
  doneQuiz(users, courses, chapters, tests, user_test, data) {
    return Client.put(
      `/users/${users}/courses/${courses}/chapters/${chapters}/tests/${tests}/user-test/${user_test}`,
      data
    );
  },
  getListSharePermission(id, params) {
    return Client.get(`${resource}/${id}/share`, { params: params });
  },
  sharePermission(id, data) {
    return Client.post(`${resource}/${id}/share`, data);
  },
  putCondition(id, data) {
    return Client.put(`${resource}/${id}/completion-condition`, data);
  },
  getCoursePromotion(data) {
    return Client.get(
      `/users/${data.users}/courses/${data.courses}/promotions`
    );
  }
};
