<template>
    <a-layout class="layout-default" id="layout-default" :class="[layoutClass]">
        <a-layout-content>
            <router-view />
        </a-layout-content>
    </a-layout>
</template>

<script>
export default ({
    name: 'DefaultLayout',
    data() {
        return {
        }
    },
    computed: {
        layoutClass() {
            return this.$route.meta.layoutClass
        }
    },
})

</script>