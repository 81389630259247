import Client from "./clients/AxiosClient";
const path = "/promotions";

export default {
  get(params) {
    return Client.get(`${path}`, { params: params });
  },

  getById(id) {
    return Client.get(`${path}/${id}`);
  },

  create(promotion) {
    return Client.post(`${path}`, promotion);
  },

  update(id, promotion) {
    return Client.put(`${path}/${id}`, promotion);
  },

  delete(id) {
    return Client.delete(`${path}/${id}`);
  },
  getReport(params) {
    return Client.get(`${path}/report/total`, { params: params });
  }
};
