<template>
	<a-layout-footer>
		<a-row justify="space-between">
			<a-col> Phát triển bởi Catcuri Team </a-col>
			<a-col> Ver 1.0 </a-col>
		</a-row>
	</a-layout-footer>
</template>
<script>
export default {
	name: 'FooterLayout'
};
</script>
