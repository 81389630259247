import UserRepository from "./UserRepository";
import JobTitleRepository from "./JobTitleRepository";
import DepartmentRepository from "./DepartmentRepository";
import CourseRepository from "./CourseRepository";
import RoleRepository from "./RoleRepository";
import PartnerRepository from "./PartnerRepository";
import ContactPartnerRepository from "./ContactPartnerRepository";
import PartnerUserRepository from "./PartnerUserRepository";
import LessonRepository from "./LessonRepository";
import TopicRepository from "./TopicRepository";
import TestRepository from "./TestRepository";
import QuestionRepository from "./QuestionRepository";
import WorkshopRepository from "./WorkshopRepository";
import SharedRepository from "./ShareRepository";
import PartnerStoreRepository from "./PartnerStoreRepository";
import AddressRepository from "./AddressRepository";
import KpiRepository from "./KpiRepository";
import BadgeRepository from "./BadgeRepository";
import KpiUserRepository from "./KpiUsersRepository";
import PartnerBadgeRepository from "./PartnerBadgeRepository";
import PromotionRepository from "./PromotionRepository";
import GiftRepository from "./GiftRepository";
import PromotionCourseRepository from "./PromotionCourseRepository";
import InventoryRepository from "./InventoryRepository";
import InventoryTransferRepository from "./InventoryTransfer";
import InventoryReportRepository from "./InventoryReport";
const repositories = {
  users: UserRepository,
  jobTitles: JobTitleRepository,
  departments: DepartmentRepository,
  courses: CourseRepository,
  roles: RoleRepository,
  partners: PartnerRepository,
  contacts_partner: ContactPartnerRepository,
  partners_users: PartnerUserRepository,
  lessons: LessonRepository,
  topics: TopicRepository,
  tests: TestRepository,
  questions: QuestionRepository,
  workshops: WorkshopRepository,
  share: SharedRepository,
  store: PartnerStoreRepository,
  address: AddressRepository,
  kpi: KpiRepository,
  badge: BadgeRepository,
  kpi_users: KpiUserRepository,
  partner_badges: PartnerBadgeRepository,
  promotions: PromotionRepository,
  gifts: GiftRepository,
  promotion_courses: PromotionCourseRepository,
  inventories: InventoryRepository,
  warehouse_transfer: InventoryTransferRepository,
  inventory_report: InventoryReportRepository
};

export default {
  get: (name) => repositories[name]
};
