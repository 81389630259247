import RepositoryFactory from "../../repositories/RepositoryFactory";
import MapDataTree from "@/public/js/common/map-tree";
import MapData from "@/public/js/common/tranfer-data";
const UserRepository = RepositoryFactory.get("users");
const JobTitleRepository = RepositoryFactory.get("jobTitles");
const DepartmentRepository = RepositoryFactory.get("departments");
const RolesRepository = RepositoryFactory.get("roles");
const TopicRepository = RepositoryFactory.get("topics");
const PartnerRepository = RepositoryFactory.get("partners");

const state = {
  users: null,
  job_titles: null,
  departments: null,
  roles: null,
  curentRole: null,
  usersRole: null,
  topics: null,
  partners: null,
  stores: null,
};

const getters = {
  StateUser: (state) => state.users,
  StateJobTitle: (state) => state.job_titles,
  StateDepartment: (state) => state.departments,
  StateRoles: (state) => state.roles,
  StateCurentRole: (state) => state.curentRole,
  StateUsersRole: (state) => state.usersRole,
  StateTopic: (state) => state.topics,
  StatePartner: (state) => state.partners,
};
// api action
const actions = {
  async listUserPaging({ commit }) {
    try {
      let result = await UserRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs.length > 0) {
          let data = MapData.mapData(result.data.data.docs);
          await commit("setUser", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAllJobTitle({ commit }) {
    try {
      let result = await JobTitleRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs.length > 0) {
          let data = MapDataTree.mapping(result.data.data.docs);
          await commit("setJobTitle", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAllDepartment({ commit }) {
    try {
      let result = await DepartmentRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs.length > 0) {
          let data = MapDataTree.mapping(result.data.data.docs);
          await commit("setDepartment", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAllRoles({ commit }) {
    try {
      let result = await RolesRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs.length > 0) {
          await commit("setRoles", result.data.data.docs);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getCurentRole({ commit }, id) {
    const role = this.state.auth.roles.find((role) => role._id === id);
    await commit("setCurentRole", role);
  },
  async createRole({ commit }, payload) {
    try {
      let result = await RolesRepository.create(payload);
      if (result.data.status == 200) {
        console.log(result.data.data);
        if (result.data.data) {
          await commit("setCurentRole", result.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getUsersRole({ commit }, params) {
    try {
      const roleId = this.state.auth.curentRole?._id;
      if (roleId) {
        let result = await RolesRepository.getUser(roleId, params);
        if (result.data.status == 200) {
          await commit("setUsersRole", result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async updateRole({ commit }, payload) {
    try {
      const roleId = this.state.auth.curentRole._id;
      let result = await RolesRepository.update(roleId, payload);
      if (result.data.status == 200) {
        console.log(result.data.data);
        if (result.data.data) {
          await commit("setCurentRole", result.data.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async removeCurentRole({ commit }) {
    await commit("removeCurentRole");
    await commit("removeCurentRole");
  },
  async getAllTopic({ commit }) {
    try {
      let result = await TopicRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs.length > 0) {
          let data = MapDataTree.mapping(result.data.data.docs);
          await commit("setTopic", data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getAllPartner({ commit }) {
    try {
      let result = await PartnerRepository.get({
        limit: 100,
        page: 1,
        status: "ACTIVE",
      });
      if (result.data.code == 1) {
        if (result.data.data.docs?.length > 0) {
          await commit("setPartner", result.data.data.docs);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
};
const mutations = {
  setUser(state, users) {
    state.users = users;
  },
  setJobTitle(state, job_titles) {
    state.job_titles = job_titles;
  },
  setDepartment(state, departments) {
    state.departments = departments;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  setCurentRole(state, curentRole) {
    state.curentRole = curentRole;
  },
  removeCurentRole(state) {
    state.curentRole = null;
    state.usersRole = null;
  },
  setUsersRole(state, usersRole) {
    state.usersRole = usersRole;
  },
  setTopic(state, topics) {
    state.topics = topics;
  },
  setPartner(state, partners) {
    state.partners = partners;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
