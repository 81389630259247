import Client from "./clients/AxiosClient";
const resource = "/share";
export default {
  getListSharePermission(id, params) {
    return Client.get(`${resource}/${id}`, { params: params });
  },
  sharePermission(data) {
    return Client.post(`${resource}`, data);
  },
};
