/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";
import apiRequest from "@/services/apiRequest";

const partnerDepartment: Module<any, any> = {
  namespaced: true,
  state: {
    departments: {},
    parents: {},
    currentModel: {},
  },

  getters: {
    getList(state: any) {
      return state.departments;
    },
    parents(state: any) {
      return state.parents;
    },
    currentModel(state: any) {
      return state.currentModel;
    },
  },

  mutations: {
    setDepartments(state: any, payload) {
      state.departments[payload.partnerId] = payload.data;
    },
    setParents(state: any, payload) {
      state.parents[payload.partnerId] = payload.data;
    },
    setCurrentModel(state: any, payload) {
      state.currentModel[payload.partnerId] = payload.data;
    },
  },

  actions: {
    async getList({ commit }) {
      apiRequest
        .request({
          url: "/departments?partner=" + this.state.partner.partner._id,
          method: "get",
          headers: {
            "x-view": 1,
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("setDepartments", {
              data: response.data.data.children,
              partnerId: this.state.partner.partner._id,
            });
            commit("setParents", {
              data: response.data.data,
              partnerId: this.state.partner.partner._id,
            });
          }
        })
        .catch((error) => {
          console.log("setPartnerDepartments", error);
        });
    },
    async getById({ commit }, id) {
      apiRequest
        .request({
          url: "/departments/" + id,
          method: "get",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("setCurrentModel", {
              data: response.data.data,
              partnerId: this.state.partner.partner._id,
            });
          }
        })
        .catch((error) => {
          console.log("setCurrentModelPartnerDepartment", error);
        });
    },
    async create({ commit }, payload) {
      return apiRequest
        .request({
          url: "/departments",
          method: "post",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    },
    async update({ commit }, payload) {
      return apiRequest
        .request({
          url: "/departments/" + payload.id,
          method: "put",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    },
    async delete({ commit }, payload) {
      return apiRequest
        .request({
          url: "/departments/" + payload._id,
          method: "delete",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error.response.data;
        });
    },
  },
};

export default partnerDepartment;
