/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from 'vuex';

const course: Module<any, any> = {
	namespaced: true,
	state: {
		courses: [],
		currentModel: null,
		currentViewDetail: null,
		quizData: ''
	},

	getters: {
		getList(state: any) {
			return state.courses;
		},
		currentModel(state: any) {
			return state.currentModel;
		},
		currentViewDetail(state: any) {
			return state.currentViewDetail;
		},
		quizData(state: any) {
			return state.quizData;
		}
	},

	mutations: {
		setCourses(state: any, payload) {
			state.courses = payload;
		},
		setCurrentModel(state: any, payload) {
			state.currentModel = payload;
		},
		setCurrentViewDetail(state: any, payload) {
			state.currentViewDetail = payload;
		},
		setQuizData(state: any, payload) {
			state.quizData = payload;
		}
	},

	actions: {
		QuizData({ commit }, payload) {
			commit('setQuizData', payload);
		}
	}
};

export default course;
