/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";

import RepositoryFactory from "../../repositories/RepositoryFactory";
const AddressRepository = RepositoryFactory.get("address");

const address: Module<any, any> = {
  namespaced: true,
  state: {
    provinces: [],
    districts: [],
    wards: [],

    province: null,
    district: null,
    ward: null,
  },

  getters: {
    Provinces(state: any) {
      return state.provinces;
    },

    getProvince(state: any) {
      return state.province;
    },

    getDistricts(state: any) {
      return state.districts;
    },

    getDistrict(state: any) {
      return state.district;
    },

    getWards(state: any) {
      return state.wards;
    },

    getWard(state: any) {
      return state.ward;
    },
  },

  mutations: {
    setProvinces(state: any, payload) {
      state.provinces = payload;
    },

    setProvince(state: any, payload) {
      state.province = payload;
    },
  },

  actions: {
    async getProvinces({ commit }, param) {
      const result = await AddressRepository.getProvinces(param);
      commit("setProvinces", result.data.data.docs || []);
    },

    async getProvince({ commit }, id) {
      const result = await AddressRepository.getProvinceById(id);
      commit("setCurrentProvince", result.data.data || null);
    },
  },
};

export default address;
