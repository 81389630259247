import Client from "./clients/AxiosClient";
import Export from "./clients/ExportFile";

const path = "/partners";
const subPath = "/partner-store";

export default {
  getList(params) {
    return Client.get(`${subPath}`, { params: params });
  },

  getStoreOfPartner(partnerId, params) {
    return Client.get(`${path}/${partnerId}${subPath}`, { params: params });
  },

  getById(partnerId, id) {
    return Client.get(`${path}/${partnerId}${subPath}/${id}`);
  },

  create(partnerId, store) {
    return Client.post(`${path}/${partnerId}${subPath}`, store);
  },

  update(partnerId, id, store) {
    return Client.put(`${path}/${partnerId}${subPath}/${id}`, store);
  },

  delete(partnerId, id) {
    return Client.delete(`${path}/${partnerId}${subPath}/${id}`);
  },

  import(formData) {
    return Client.post(`${path}/import`, formData);
  },

  export(partnerId, params) {
    return Export.get(`${path}/${partnerId}${subPath}/export`, {
      params: params,
    });
  },
};
