import Client from "./clients/AxiosClient";
const resource = "/job-titles";
import Export from "./clients/ExportFile";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  getInternal(params) {
    return Client.get(`${resource}/internal`, { params: params });
  },
  create(data, id) {
    return Client.post(`${resource}/${id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  export() {
    return Export.get(`${resource}/export`);
  },
};
