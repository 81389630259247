import Client from "./clients/AxiosClient";
const resource = "/tests";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  create(data) {
    return Client.post(`${resource}`, data);
  },
  update(data) {
    return Client.put(`${resource}/${data._id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  getQuestionsByTestId(id, params) {
    return Client.get(`${resource}/${id}/questions`, { params: params });
  },
};
