import axios from 'axios';

// const baseDomain = 'http://localhost:3979';
const baseDomain = process.env.API_URL || 'http://localhost:3979';
const baseURL = `${baseDomain}`;
const vuex = JSON.parse(localStorage.getItem('vuex'));
const accessToken = vuex && vuex.authGuard ? vuex.authGuard.accessToken : '';
export default axios.create({
	baseURL, // api_base_url
	mode: 'no-cors',
	timeout: 20000, // request timeout
	responseType: 'blob',
	headers: {
		'x-product': 'TEST',
		'access-token': accessToken ? accessToken : '',
		'Content-Type':
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	}
});
