export default {
  routes: [
    {
      name: "user-manager",
      displayName: "sidebar.user-manager",
      path: "#",
      icon: "TeamOutlined",
      children: [
        {
          name: "users",
          displayName: "sidebar.users",
          path: "/admin/users",
          subject: "users",
          key: 0
        },
        {
          name: "departments",
          displayName: "sidebar.departments",
          path: "/admin/departments",
          subject: "departments",
          key: 2
        },
        {
          name: "job-titles",
          displayName: "sidebar.job-titles",
          path: "/admin/job-titles",
          subject: "job-titles",
          key: 3
        },
        {
          name: "groups",
          displayName: "sidebar.groups",
          path: "/admin/groups",
          subject: "roles",
          key: 4
        }
      ]
    },
    {
      name: "partners-list",
      displayName: "sidebar.partners",
      path: "/admin/partners",
      icon: "DeploymentUnitOutlined",
      subject: "partners",
      key: 5
    },
    {
      name: "content-manager",
      displayName: "sidebar.content-manager",
      path: "#",
      icon: "BookOutlined",
      children: [
        {
          name: "courses",
          displayName: "course-sidebar.courses-list",
          path: "/admin/courses",
          subject: "courses",
          key: 8
        },
        {
          name: "topics",
          displayName: "sidebar.topic",
          path: "/admin/topics",
          subject: "topics",
          key: 9
        },
        {
          name: "lessons",
          displayName: "sidebar.lesson",
          path: "/admin/lessons",
          subject: "lessons",
          key: 10
        },
        {
          name: "questions",
          displayName: "sidebar.question",
          path: "/admin/questions",
          subject: "questions",
          key: 11
        },
        {
          name: "workshops",
          displayName: "sidebar.workshop",
          path: "/admin/workshops",
          subject: "workshops",
          key: 12
        },
        {
          name: "tests",
          displayName: "sidebar.test",
          path: "/admin/tests",
          subject: "tests",
          key: 13
        }
      ],
      key: 7
    },

    {
      name: "studying-promotion",
      displayName: "sidebar.studying-promotion",
      path: "#",
      icon: "GiftOutlined",
      children: [
        {
          name: "promotion",
          displayName: "sidebar.promotion",
          path: "/admin/promotions",
          subject: "promotion",
          key: 15
        },
        {
          name: "inventory",
          displayName: "sidebar.inventory",
          path: "/admin/inventories",
          subject: "inventories",
          key: 16
        }
      ],
      key: 14
    },

    {
      name: "kpi-menu",
      displayName: "sidebar.kpi-menu",
      path: "#",
      icon: "FlagOutlined",
      children: [
        {
          name: "kpi-dashboard",
          displayName: "sidebar.kpi-dashboard",
          path: "/admin/kpi-dashboard",
          subject: "dashboard",
          key: 19
        },
        {
          name: "kpi-manager",
          displayName: "sidebar.kpi-manager",
          path: "/admin/kpi-users",
          subject: "kpi-users",
          key: 20
        },
        {
          name: "kpis",
          displayName: "sidebar.kpis",
          path: "/admin/kpis",
          subject: "kpis",
          key: 21
        },
        {
          name: "kpi-calculating",
          displayName: "sidebar.kpi-calculating",
          path: "/admin/kpi-calculating",
          subject: "kpi-calculating",
          key: 22
        }
      ],
      key: 18
    },
    {
      name: "reports",
      displayName: "sidebar.reports",
      path: "#",
      icon: "ContainerOutlined",
      children: [
        {
          name: "promotion-report",
          displayName: "report-sidebar.promotion",
          path: "/admin/report/promotion",
          subject: "promotion-report",
          key: 24
        },
        {
          name: "inventory-report",
          displayName: "report-sidebar.inventory",
          path: "/admin/report/inventory",
          subject: "inventory-report",
          key: 25
        }
      ],
      key: 23
      //< />
    }
  ]
};
