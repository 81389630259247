import router from "@/router";

function errorRedirect(data) {
  switch (data.response.data.sys_message) {
    case "SESSION_EXPIRED":
      router.push({ name: "login" });
      break;
    case "PERMISSION_DENY":
      router.push({ name: "PageNotAuth" });
      break;
    case "system error":
      router.push({ name: "ServerErrorPage" });
      break;
    default:
      throw data;
  }
}

export default errorRedirect;
