const mapping = (data) => {
  return mapParent(
    data.map((item) => ({
      ...item,
      parent: checkData(item.parent),
    }))
  );
};
const checkData = (data) => {
  if (data) {
    if (typeof data === "object") {
      return data._id;
    } else {
      return data;
    }
  }
  return null;
};
const mapParent = (data, _id = null) => {
  let results = data
    .filter((item) => item["parent"] === _id)
    .map((item) => ({
      title: item.name,
      value: item._id,
      children: mapParent(data, item._id),
    }));
  return results;
};
export default {
  mapping,
  mapParent
};
