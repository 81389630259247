import Client from "./clients/AxiosClient";
import Export from "./clients/ExportFile";

const report = "inventory-reports/summary";
const reportExchange = "inventory-reports/exchange-detail";
const reportImport = "inventory-reports/import";
const reportExport = "inventory-reports/export";
export default {
  getReport(params) {
    return Client.get(`${report}`, { params: params });
  },
  getExcelReport(params) {
    return Export.get(`${report}/file`, { params: params });
  },
  getReportExchange(params) {
    return Client.get(`${reportExchange}`, { params: params });
  },
  getExcelReportExchange(params) {
    return Export.get(`${reportExchange}/file`, { params: params });
  },
  getReportImport(params) {
    return Client.get(`${reportImport}`, { params: params });
  },
  getExcelReportImport(params) {
    return Export.get(`${reportImport}/file`, { params: params });
  },
  getReportExport(params) {
    return Client.get(`${reportExport}`, { params: params });
  },
  getExcelReportExport(params) {
    return Export.get(`${reportExport}/file`, { params: params });
  }
};
