import { Module } from "vuex";

const sidebar: Module<any, any> = {
  namespaced: true,
  state: {
    routes: [],
    selectedKeys: null,
    openKeys: null
  },

  getters: {
    getSideBars(state: any) {
      return state;
    },
  },

  mutations: {
    setRoutes(state: any, payload) {
      state.routes = payload;
    },
    setSelectedKeys(state: any, payload) {
      state.selectedKeys = payload;
    },
    setOpenKeys(state: any, payload) {
      state.openKeys = payload;
    },
  }
};

export default sidebar;
