import Client from "./clients/AxiosClient";
const resource = "/partners/users";
const resources = "/partners";
export default {
  get(id, params) {
    return Client.get(`${resources}/${id}/users`, { params: params });
  },
  create(partner) {
    return Client.post(`${resource}`, partner);
  },
  update(partner, id) {
    return Client.put(`${resource}/${id}`, partner);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  importMaster(formData, id) {
    return Client.post(`${resources}/${id}/users/import`, formData);
  }
};
