/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";
import apiRequest from "@/services/apiRequest";

import ErrorHandler from "../../repositories/clients/ErrorResponseHandle";

const topic: Module<any, any> = {
  namespaced: true,
  state: {
    topics: [],
    parents: [],
    currentModel: null,
  },

  getters: {
    getList(state: any) {
      return state.topics;
    },
    parents(state: any) {
      return state.parents;
    },
    currentModel(state: any) {
      return state.currentModel;
    },
  },

  mutations: {
    setTopics(state: any, payload) {
      state.topics = payload;
    },
    setParents(state: any, payload) {
      state.parents = payload;
    },
    setCurrentModel(state: any, payload) {
      state.currentModel = payload;
    },
  },

  actions: {
    async getList({ commit }) {
      apiRequest
        .request({
          url: "/topics",
          method: "get",
          headers: {
            "x-view": 1,
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          commit("setTopics", response.data.data.children);
          commit("setParents", response.data.data);
        })
        .catch((error) => {
          console.log("setTopics", error);
          ErrorHandler(error);
        });
    },
    async getById({ commit }, id) {
      apiRequest
        .request({
          url: "/topics/" + id,
          method: "get",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          commit("setCurrentModel", response.data.data);
        })
        .catch((error) => {
          console.log("getById", error);
          ErrorHandler(error);
        });
    },
    async create({ commit }, payload) {
      return apiRequest
        .request({
          url: "/topics",
          method: "post",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
    async update({ commit }, payload) {
      return apiRequest
        .request({
          url: "/topics/" + payload.id,
          method: "put",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
    async delete({ commit }, payload) {
      return apiRequest
        .request({
          url: "/topics/" + payload._id,
          method: "delete",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
    async upload({ commit }, payload) {
      return apiRequest
        .request({
          url: "/topics/upload",
          method: "post",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
  },
};

export default topic;
