/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";
import apiRequest from "@/services/apiRequest";

import ErrorHandler from "../../repositories/clients/ErrorResponseHandle";

const job: Module<any, any> = {
  namespaced: true,
  state: {
    jobs: [],
    parents: [],
    currentModel: null,
  },

  getters: {
    getList(state: any) {
      return state.jobs;
    },
    parents(state: any) {
      return state.parents;
    },
    currentModel(state: any) {
      return state.currentModel;
    },
  },

  mutations: {
    setJobs(state: any, payload) {
      state.jobs = payload;
    },
    setParents(state: any, payload) {
      state.parents = payload;
    },
    setCurrentModel(state: any, payload) {
      state.currentModel = payload;
    },
  },

  actions: {
    async getList({ commit }, { partnerId, xView }) {
      apiRequest
        .request({
          url: `/job-titles?partner=${partnerId}`,
          method: "get",
          headers: {
            "x-view": xView ?? 1,
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("setJobs", response.data.data.children);
          }
        })
        .catch((error) => {
          console.log("JobLists", error);
          ErrorHandler(error);
        });
    },
    async getListParent({ commit }) {
      apiRequest
        .request({
          url: "/job-titles?limit=100",
          method: "get",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("setParents", response.data.data.docs);
          }
        })
        .catch((error) => {
          console.log("setParents", error);
          ErrorHandler(error);
        });
    },
    async getById({ commit }, id) {
      apiRequest
        .request({
          url: "/job-titles/" + id,
          method: "get",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          if (response.data.code == 1) {
            commit("setCurrentModel", response.data.data);
          }
        })
        .catch((error) => {
          console.log("setCurrentModel", error);
          ErrorHandler(error);
        });
    },
    async create({ commit }, payload) {
      return apiRequest
        .request({
          url: "/job-titles",
          method: "post",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
    async update({ commit }, payload) {
      return apiRequest
        .request({
          url: "/job-titles/" + payload.id,
          method: "put",
          data: payload,
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
    async delete({ commit }, payload) {
      return apiRequest
        .request({
          url: "/job-titles/" + payload._id,
          method: "delete",
          headers: {
            "access-token": this.state.authGuard.accessToken,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          ErrorHandler(error);
          return error.response.data;
        });
    },
  },
};

export default job;
