/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";

const partner: Module<any, any> = {
  namespaced: true,
  state: {
    partners: [],
    partner: {},
  },

  getters: {
    getList(state: any) {
      return state.partners;
    },
    selectedPartner(state: any) {
      return state.partner;
    },
  },

  mutations: {
    setPartners(state: any, payload) {
      state.partners = payload;
    },
    setPartner(state: any, payload) {
      state.partner = payload;
    },
  },
};

export default partner;
