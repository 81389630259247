import Client from "./clients/AxiosClient";

const PARENT_PATH = "/promotions";
const PATH = "/promotion-courses";

export default {
  get(promoId, params) {
    const PARENT_PATH_ID = `${PARENT_PATH}/${promoId}${PATH}`;
    return Client.get(`${PARENT_PATH_ID}`, { params: params });
  },

  getById(id) {
    return Client.get(`${PATH}/${id}`);
  },

  create(data) {
    return Client.post(`${PATH}`, data);
  },

  update(id, data) {
    return Client.put(`${PATH}/${id}`, data);
  },

  delete(promoId, id) {
    return Client.delete(`${PARENT_PATH}/${promoId}${PATH}/${id}`);
  },
};
