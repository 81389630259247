import Client from "./clients/AxiosClient";
const resource = "/contact-partner";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  create(partner) {
    return Client.post(`${resource}`, partner);
  },
  update(partner) {
    return Client.put(`${resource}/${partner._id}`, partner);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  //   getById(id) {
  //     return Client.get(`${resource}/${id}`);
  //   },
};
