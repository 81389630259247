import Client from "./clients/AxiosClient";
const path = "/kpi-users";

export default {
  getList(params) {
    return Client.get(`${path}`, { params: params });
  },

  getById(id) {
    return Client.get(`${path}/${id}`);
  },

  create(kpiUser) {
    return Client.post(`${path}`, kpiUser);
  },

  update(id, kpiUser) {
    return Client.put(`${path}/${id}`, kpiUser);
  },

  delete(id) {
    return Client.delete(`${path}/${id}`);
  },
};
