import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./modules/auth";
import authGuard from "./modules/authGuard";
import department from "./modules/department";
import partner from "./modules/partner";
import job from "./modules/job";
import partnerDepartment from "./modules/partner-department";
import partnerJob from "./modules/partner-job";
import course from "./modules/course";
import topic from "./modules/topic";
import test from "./modules/test";
import sidebar from "./modules/sidebar";
import address from "./modules/address";

export default createStore({
  strict: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    authGuard,
    department,
    partner,
    job,
    partnerDepartment,
    partnerJob,
    course,
    topic,
    test,
    sidebar,
    address,
  },
  plugins: [createPersistedState()],
});
