import Client from './clients/AxiosClient';
const resource = '/roles';
const modules = '/modules';
const userRole = '/user-roles';

export default {
	get(params) {
		return Client.get(`${resource}`, { params: params });
	},
	create(payload) {
		return Client.post(`${resource}`, payload);
	},
	update(roleID, payload) {
		return Client.put(`${resource}/${roleID}`, payload);
	},
	getUser(id, params) {
		return Client.get(`${userRole}/${id}`, { params: params });
	},
	updateRoleUser(roleID, listID) {
		return Client.put(`${userRole}/${roleID}`, listID);
	},
	deleteRoleUser(listID) {
		return Client.delete(`${userRole}`, { data: listID });
	},
	getModule() {
		return Client.get(`${modules}`);
	}
};
