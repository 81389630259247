import Client from "./clients/AxiosClient";
const path = "/partner-badges";

export default {
  get(params) {
    return Client.get(`${path}`, { params: params });
  },
  getById(id) {
    return Client.get(`${path}/${id}`);
  },

  create(data) {
    return Client.post(`${path}`, data);
  },
  update(data) {
    return Client.put(`${path}/${data._id}`, data);
  },

  delete(id) {
    return Client.delete(`${path}/${id}`);
  },
  getApprovedCourses(params){
    return Client.get(`/approved-courses`, { params: params });
  }
};
