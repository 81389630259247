
import { defineComponent, ref } from "vue";
import SideBarRoute from "./SideBarRoute";
import { Icon } from "../icon/icon";
export default defineComponent({
  props: {
    collapsed: {
      Type: Boolean,
      default: true,
    },
    routes: {
      type: Object,
      default: SideBarRoute.routes,
    },
    selectedKeys: {
      type: [String, Array],
      default: ref(["users"]),
    },
    openKeys: {
      type: [String, Array],
      default: ref(["user-manager"]),
    },
  },
  components: { Icon },
  methods: {
    isAllChildrenPermission(menuKey: any) {
      let enableMenu = false;
      let childrenRoutes = this.routes[menuKey].children;
      childrenRoutes.forEach((child: any) => {
        if (this.$can("read", child.subject)) {
          enableMenu = true;
        }
      });
      return enableMenu;
    },
    childrenHavePermission(menuChildren: any) {
      let menuChild: any = [];
      menuChildren.forEach((child: any) => {
        if (this.$can("read", child.subject)) {
          menuChild.push(child);
        }
      });
      return menuChild;
    },
  },
});
