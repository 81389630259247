import Client from "./clients/AxiosClient";
const path = "/kpis";

export default {
  get(params) {
    return Client.get(`${path}`, { params: params });
  },

  // getStoreOfPartner(partnerId, params) {
  //   return Client.get(`${path}/${partnerId}${subPath}`, { params: params });
  // },

  getById(id) {
    return Client.get(`${path}/${id}`);
  },

  create(kpi) {
    return Client.post(`${path}`, kpi);
  },

  update(id, kpi) {
    return Client.put(`${path}/${id}`, kpi);
  },

  delete(id) {
    return Client.delete(`${path}/${id}`);
  },

  settlement(kpis) {
    return Client.post(`${path}/settlement`, kpis);
  },
};
