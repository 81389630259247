<template>
  <a-layout class="layout-dashboard" id="layout-dashboard">
    <dashboard-sidebar
      :collapsed="collapsed"
      :routes="sidebar.routes"
      :openKeys="sidebar.openKeys"
      :selectedKeys="sidebar.selectedKeys"
    />
    <a-layout :style="{ marginLeft: collapsed ? '80px' : '270px' }">
      <a-layout-header> </a-layout-header>
      <a-layout-header
        :style="{
          position: 'fixed',
          zIndex: 1,
          width: `calc(100% - ${collapsed ? '80px' : '270px'}`,
        }"
      >
        <a-row type="flex" align="center" justify="space-between" class="mt-0">
          <a-col :span="20">
            <menu-unfold-outlined
              v-if="collapsed"
              :style="{ fontSize: '20px', color: '#C3C5DD' }"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined
              v-else
              :style="{ fontSize: '20px', color: '#C3C5DD' }"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
          </a-col>
          <a-col :span="4">
            <div class="header-right-wrapper">
              <a-dropdown placement="bottomRight" :trigger="['click']">
                <a class="ant-dropdown-link" @click.prevent>
                  <span class="mr-12 name-user">{{ loginInfo }}</span>
                  <a-avatar :size="40">
                    <template #icon>
                      <UserOutlined />
                    </template>
                  </a-avatar>
                </a>
                <template #overlay>
                  <a-menu class="header-dropdown">
                    <a-menu-item :class="['header-item']" key="0">
                      <home-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <a href="/home">{{ $t("menu.dropdown.home") }}</a>
                    </a-menu-item>
                    <a-menu-item :class="['header-item']" key="1">
                      <setting-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <a href="/admin/users">{{
                        $t("menu.dropdown.setting")
                      }}</a>
                    </a-menu-item>
                    <a-menu-item :class="['header-item']" key="2">
                      <user-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <a href="/user-detail">{{
                        $t("menu.dropdown.user-detail")
                      }}</a>
                    </a-menu-item>
                    <!-- user-detail -->
                    <a-menu-item>
                      <file-text-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <a href="/my-courses">{{
                        $t("menu.dropdown.my-course")
                      }}</a>
                    </a-menu-item>
                    <a-menu-item
                      :class="['header-item']"
                      key="3"
                      v-if="$can('read', 'system-information')"
                    >
                      <info-circle-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <router-link to="/admin/system-information">{{
                        $t("menu.dropdown.system-info")
                      }}</router-link>
                    </a-menu-item>
                    <a-menu-item :class="['header-item']" key="4">
                      <export-outlined
                        :style="{
                          fontSize: '16px',
                          paddingRight: '16px',
                        }"
                      />
                      <a href="#" @click="logout">{{
                        $t("menu.dropdown.logout")
                      }}</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout-content
        :style="{ padding: '0 16px', minHeight: 'calc(100vh - 135px)' }"
      >
        <router-view />
      </a-layout-content>
      <dashboard-footer />
    </a-layout>
  </a-layout>
</template>
<script>
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  ExportOutlined,
  FileTextOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import DashboardSidebar from "../components/sidebar/dashboard-sidebar.vue";
import DashboardFooter from "../components/footer/default.vue";
import SidebarRoutes from "@/components/sidebar/SideBarRoute";
export default defineComponent({
  setup() {
    return {
      selectedKeys: ref(["1"]),
      collapsed: ref(false),
    };
  },
  components: {
    DashboardSidebar,
    DashboardFooter,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    HomeOutlined,
    SettingOutlined,
    InfoCircleOutlined,
    ExportOutlined,
    FileTextOutlined,
  },
  created() {
    this.$store.commit("sidebar/setRoutes", SidebarRoutes.routes);
  },
  computed: {
    layoutClass() {
      return this.$route.meta.layoutClass;
    },
    sidebar() {
      return this.$store.getters["sidebar/getSideBars"];
    },
    loginInfo() {
      return this.$store.getters["authGuard/getName"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("authGuard/logout");
    },
  },
});
</script>
<style></style>
