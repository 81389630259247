/* eslint-disable @typescript-eslint/no-explicit-any */
import { Module } from "vuex";

const course: Module<any, any> = {
  namespaced: true,
  state: {
    tests: [],
    currentModel: null,
  },

  getters: {
    getList(state: any) {
      return state.tests;
    },
    currentModel(state: any) {
      return state.currentModel;
    },
  },

  mutations: {
    setTests(state: any, payload) {
      state.tests = payload;
    },
    setCurrentModel(state: any, payload) {
      state.currentModel = payload;
    },
  },

  actions: {},
};

export default course;
