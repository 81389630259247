import Client from "./clients/AxiosClient";
const resource = "/workshops";
export default {
  get(params) {
    return Client.get(`${resource}`, { params: params });
  },
  create(data) {
    return Client.post(`${resource}`, data);
  },
  update(data, id) {
    return Client.put(`${resource}/${id}`, data);
  },
  delete(id) {
    return Client.delete(`${resource}/${id}`);
  },
  getById(id) {
    return Client.get(`${resource}/${id}`);
  },
  upload(formData) {
    return Client.post(`${resource}/upload`, formData);
  },
};
